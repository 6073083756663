import React from 'react'
import { css } from 'linaria'
import { fontSizes } from '../constants'

const Page: React.FC = () => <strong className={style}>404 Not Found</strong>

const style = css`
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  font-size: ${fontSizes.midium};
  justify-content: center;
`

export default Page
